import _ from "lodash"

export let rsvpCodeTemplate = _.template(`<div class="info__rsvp-form-wrapper">
    <form class="info__rsvp-form info__rsvp-form--code">
		<span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--code">RSVP Code</span>
        <input class="info__rsvp-input info__rsvp-input--code" name="code" type="text" />
		<span class="info__rsvp-tip">Please provide the RSVP code found on your invitation.</span>
        <input class="info__rsvp-submit" type="submit" value="Submit" />
    </form>
    <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--empty">Please provide an RSVP code. You can find this on your invitation, which you will receive in the mail.</span>
    <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--invalid">The RSVP code you provided is invalid. You can find your RSVP code on your invitation, which you will receive in the mail.</span>
</div>`);

export let mealSelectTemplate = _.template(`<select class="info__rsvp-input info__rsvp-input--meal info__rsvp-input--<%= guest %>" name="meal-<%= guest %>">
        <!--<option class="info__rsvp-input info__rsvp-option--meal" name="unselected" hidden disabled selected value>-</option>-->
        <option class="info__rsvp-input info__rsvp-option--meal" value="unselected" selected>-</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="wellington-pavlova">Butternut squash wellington (main) and pavlova (dessert)</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="wellington-pot">Butternut squash wellington (main) and chocolate pot (dessert)</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="chicken-pavlova">Roasted chicken (main) and pavlova (dessert)</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="chicken-pot">Roasted chicken (main) and chocolate pot (dessert)</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="beef-pavlova">Slow braised beef (main) and pavlova (dessert)</option>
        <option class="info__rsvp-input info__rsvp-option--meal" value="beef-pot">Slow braised beef (main) and chocolate pot (dessert)</option>
    </select>`);

export let rsvpDetailsTemplate = _.template(`<div class="info__rsvp-form-wrapper">
    <form class="info__rsvp-form info__rsvp-form--details">
        <input type="hidden" name="form-name" value="rsvp-form" />
        <div class="info__rsvp-form-late-message"><p>Unfortunately the RSVP deadline for guests invited to the wedding breakfast passed on 23 June and food selections have been confirmed with our caterer. However, we'd still love for you to join us at the ceremony and evening celebration.</p><p>If you think there's been a mistake, please reach out to us as soon as possible.</p></div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--name">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--name">Name</span>
            <div class="info__rsvp-form-input-element-group info__rsvp-form-input-element-group--name">
                <label class="info__rsvp-input-label info__rsvp-input-label--name info__rsvp-input-label--first">
                    <input class="info__rsvp-input info__rsvp-input--name info__rsvp-input--first" name="firstName" type="text" />
                    <span class="info__rsvp-input-text info__rsvp-input-text--first">First name</span>
                </label>
                <label class="info__rsvp-input-label info__rsvp-input-label--name info__rsvp-input-label--last">
                    <input class="info__rsvp-input info__rsvp-input--name info__rsvp-input--last" name="lastName" type="text" />
                    <span class="info__rsvp-input-text info__rsvp-input-text--last">Last name</span>
                </label>
            </div>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--first-missing">Please provide a first name.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--first-short">The first name you provided is too short.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--last-missing">Please provide a last name.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--last-short">The last name you provided is too short.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--contact">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--contact">Contact</span>
            <div class="info__rsvp-form-input-element-group info__rsvp-form-input-element-group--contact">
                <label class="info__rsvp-input-label info__rsvp-input-label--contact info__rsvp-input-label--phone">
                    <input class="info__rsvp-input info__rsvp-input--contact info__rsvp-input--phone" name="phone" type="text" />
                    <span class="info__rsvp-input-text info__rsvp-input-text--phone">Phone number</span>
                </label>
                <label class="info__rsvp-input-label info__rsvp-input-label--contact info__rsvp-input-label--email">
                    <input class="info__rsvp-input info__rsvp-input--contact info__rsvp-input--email" name="email" type="text" />
                    <span class="info__rsvp-input-text info__rsvp-input-text--email">Email address</span>
                </label>
            </div>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--phone-missing">Please provide a phone number.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--phone-invalid">The phone number you provided is invalid.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--email-missing">Please provide an email address.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--email-invalid">The email address you provided is invalid.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--events">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--events">What events will you/your party attend</span>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--ceremony">
                <input class="info__rsvp-input-option" type="checkbox" id="ceremony" name="ceremony">
                <label for="ceremony">I/we will attend the ceremony (12:30-2:15pm)</label>
            </div>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--breakfast">
                <input class="info__rsvp-input-option" type="checkbox" id="breakfast" name="breakfast">
                <label for="breakfast">I/we will attend the canapés & breakfast (2:45-7pm)</label>
            </div>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--celebration">
                <input class="info__rsvp-input-option" type="checkbox" id="celebration" name="celebration">
                <label for="celebration">I/we will attend the evening celebration (7pm-12am)</label>
            </div>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--none">
                <input class="info__rsvp-input-option" type="checkbox" id="none" name="none">
                <label for="none">I/we will not be able to attend any events</label>
            </div>
            <span class="info__rsvp-tip">If guests in your party will be attending different events, please submit the form once for each member/group.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--events-empty">Please select which events you will be able to attend. If you cannot join us, select 'I/we will not be able to attend any events'.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--events-invalid">Please select the events you will attend <u>or</u> indicate that you will not be available for any events.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--meal">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--meal">Meal</span>

            <span class="info__rsvp-tip">
                <p>Please select what meal you would like to have from the following options.</p>
                <p class="info__rsvp-menu-header">Mains</p>
                <p><strong>Butternut squash wellington (v):</strong> Butternut squash and spinach wellington, with balsamic dressing and a pesto sauce.</p>
                <p><strong>Roasted chicken:</strong> Roasted chicken breast served with artichokes and tomatoes, topped with parmesan.</p>
                <p><strong>Slow braised beef:</strong> Slow braised beef served with shallots and garlic on bed of rocket and herb flowers.</p>
                <p class="info__rsvp-menu-header">Desserts</p>
                <p><strong>Pavlova:</strong> Meringue dessert, served with summer berries and topped with chocolate flakes.</p>
                <p><strong>Molten chocolate pot:</strong> Baked chocolate sponge dessert with a warm gooey centre.</p>
            </span>
            <%= mealSelectTemplate({guest: "primary"}) %>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--meal-unselected">You must select a meal, since you indicated that you will attend the wedding breakfast.</span>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--meal-selected">You cannot select a meal, since you indicated that you will not attend the wedding breakfast.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--other-guests">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--other-guests">Other guests</span>
            <span class="info__rsvp-tip">RSVP for any other guests in your party. Please only add guests who were listed on your invitation and will attend the events selected above.</span>
            <div class="info__rsvp-form-guests"></div>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--guests-numerous">Sorry, you cannot submit an RSVP with more than ten guests in your party. Please resubmit with smaller groups.</span>
            <button class="info_rsvp-form-guest-button info_rsvp-form-guest-button--add" type="button"><i class="fas fa-user-plus info_rsvp-form-guest-icon"></i> Add Another Guest</button>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--dietary-reqs">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--dietary-reqs">Any dietary requirements</span>
            <input class="info__rsvp-input info__rsvp-input--dietary-reqs" name="dietaryReqs" type="text" />
            <span class="info__rsvp-tip">Please indicate <u>which guests</u> the requirements apply to.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--coach">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--coach">Coach option</span>
            <span class="info__rsvp-tip">We are considering organising coaches from the church to the reception venue and to help return guests to their accommodation after the reception. It is now too late for guests to register interest in the coach through the RSVP form, but please reach out to us if you want to see whether there is space.</span>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--church-coach">
                <input class="info__rsvp-input-option info__rsvp-input-option--church-coach" id="churchCoach" name="churchCoach" type="checkbox" />
                <label for="churchCoach">I/we may be interested in travelling from the church to the reception venue by coach</label>
            </div>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--church-coach-selected">You cannot this option, because you indicated that you would not attend both the ceremony and the wedding breakfast.</span>
            <div class="info__rsvp-form-input-options info__rsvp-form-input-options--venue-coach">
                <input class="info__rsvp-input-option info__rsvp-input-option--venue-coach" id="venueCoach" name="venueCoach" type="checkbox" />
                <label for="venueCoach">I/we may be interested in travelling from the reception venue to nearer our accommodation by coach</label>
            </div>
            <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--venue-coach-selected">You cannot this option, because you indicated that you would not attend the reception.</span>
        </div>
        <div class="info__rsvp-form-input-group info__rsvp-form-input-group--comments">
            <span class="info__rsvp-form-input-group-text info__rsvp-form-input-group-text--comments">Any other queries or comments</span>
            <input class="info__rsvp-input info__rsvp-input--comments" name="comments" type="text" />
        </div>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--network">Could not send RSVP due to a network error. Please retry.</span>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--validation">Could not send RSVP as there was a form validation error. Please correct any issues above.</span>
        <input class="info__rsvp-submit" type="submit" value="Submit RSVP" />
    </form>
    <div class="info__rsvp-overlay info__rsvp-overlay--hidden"></div>
</div>`);

export let guestTemplate = _.template(`
    <div class="info__rsvp-form-guest">
        <div class="info__rsvp-form-input-element-group info__rsvp-form-input-element-group--guest">
            <label class="info__rsvp-input-label info__rsvp-input-label--guest-name">
                <input class="info__rsvp-input info__rsvp-input--guest-name" name="guest-name-<%= guest %>" type="text" />
                <span class="info__rsvp-input-text info__rsvp-input--<%= guest %> info__rsvp-input-text--name">Name</span>
            </label>
            <label class="info__rsvp-input-label info__rsvp-input-label--guest-meal">
                <%= mealSelectTemplate({guest: guest}) %>
                <span class="info__rsvp-input-text info__rsvp-input-text--guest-meal">Meal</span>
            </label>
        </div>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--guest-<%= guest %>-name-missing">Please provide a name for this guest.</span>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--guest-<%= guest %>-name-short">The name you provided for this guests is too short.</span>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--guest-<%= guest %>-meal-unselected">You must select a meal for this guest, since you indicated that he/she will attend the wedding breakfast.</span>
        <span class="info__rsvp-form-error info__rsvp-form-error--hidden info__rsvp-form-error--guest-<%= guest %>-meal-selected">You cannot select a meal for this guest, since you indicated that he/she will not attend the wedding breakfast.</span>
        <button class="info_rsvp-form-guest-button info_rsvp-form-guest-button--remove" type="button"><i class="fas fa-user-minus info_rsvp-form-guest-icon"></i> Remove Guest</button>
    </div>
`);

export let rsvpSubmittedTemplate = _.template(`<div class="info__rsvp-form-wrapper">
    <span class="info_rsvp-form-thanks"><%= coming ? "We are so pleased that you will be joining us!" : "We're sorry to be missing you!" %></span>
    <div class="info_rsvp-form-submitted-message">Thank you for submitting your RSVP. If you would like to send a new RSVP for a different guest, please click below.</div>
    <button class="info_rsvp-form-restart">Start new RSVP</button>
</div>`);
