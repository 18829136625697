let weddingTimes = [
    {label: "Ceremony", startTime: new Date(2021, 7, 14, 12, 45)},
    {label: "Cocktail Hour", startTime: new Date(2021, 7, 14, 14, 45)},
    {label: "Breakfast", startTime: new Date(2021, 7, 14, 16, 15)},
    {label: "Bar", startTime: new Date(2021, 7, 14, 18, 30)},
    {label: "Dancing", startTime: new Date(2021, 7, 14, 19, 30)},
    {label: "Carriages", startTime: new Date(2021, 7, 15, 0, 30)},
]

function millisecondsToString(ms) {
    let seconds = Math.round(ms / 1000)
    let minutes = seconds / 60
    let hours = minutes / 60
    let days = hours / 24
    let fullDays = Math.floor(days)
    let fullHours = Math.floor(hours) - (fullDays * 24)
    let fullMinutes = Math.floor(minutes) - (fullDays * 24 * 60) - (fullHours * 60)
    let fullSeconds = Math.floor(seconds) - (fullDays * 24 * 60 * 60) - (fullHours * 60 * 60 ) - (fullMinutes * 60)

    let text = "";
    if (fullDays) {
        text += fullDays + " Days "
    }
    if (fullHours || text) {
        text += fullHours + " Hrs "
    }
    if (fullMinutes || text) {
        text += fullMinutes + " Mins "
    }
    if (fullSeconds || text) {
        text += fullSeconds + " Secs "
    }
    return text.trim()
}

function getCountdownString() {
    let now = new Date()
    let remaining = weddingTimes[0].startTime - now
    if (remaining > 0) {
        return millisecondsToString(remaining)
    }
    let last = null
    for (let event of weddingTimes) {
        if (now < event.startTime) {
            return "Ongoing: " + last.label;
        }
        last = event;
    }
    return "We're married! Thank you helping us create such a special day."
}

export function updateCountdown() {
    let countdownDiv = document.getElementsByClassName("info__countdown");
    let countdownStr = getCountdownString();
    if (countdownDiv[0].innerText != countdownStr) {
        countdownDiv[0].innerText = countdownStr;
    }
}
