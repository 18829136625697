let img = 1;
const images = 6;

export function updateImg() {
    let prev = document.getElementsByClassName("slideshow__img--" + img)[0];
    img = (img % images) + 1;
    let next = document.getElementsByClassName("slideshow__img--" + img)[0];
    next.classList.add("slideshow__img--active");
    setTimeout(prev.classList.remove.bind(prev.classList, "slideshow__img--active"), 1000);
}
