import _ from "lodash"

export let eventTemplate = _.template(`<div class="info__subsection-card info__subsection-card--wide">
    <div class="info__subsection-title-bar"><!--
        --><div class="info__event-time"><% if (_.isString(time)) {%><!--
            --><span class="info__event-time-period"><%= time %></span><!--
        --><% } else {%><!--
            --><span class="info__event-time-period"><%= time.startTime %></span><!--
            --><span class="info__event-time-dash">-</span><!--
            --><span class="info__event-time-period"><%= time.endTime %></span><% } %><!--
        --></div>
        <div class="info__event-title"><%= title %></div>
    </div>
    <div class="info__subsection-details-wrapper info__subsection-details-wrapper--date">
        <div class="info__subsection-details-stub"></div>
        <div class="info__subsection-details">
            <% if (location) { %><a href="<%= location.link %>"><%= location.text %></a><% } %>
            <% for (let detail of details) { %>
            <div class="info__detail info__detail--event"><%= detail.text %></div>
            <% } %>
        </div>
    </div>
</div>`)
