import schedule from "./schedule.json"
import sectionContent from "./content.json"
import {eventTemplate} from "./events-templates.js"
import {rsvpCodeTemplate, rsvpDetailsTemplate, rsvpSubmittedTemplate, mealSelectTemplate, guestTemplate} from "./rsvp-templates.js"
import {itemTemplate, subsectionTitle} from "./info-templates.js"
import _ from "lodash"

function htmlToElement(html) {
    var template = document.createElement("template");
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
}

let icons = {
    "food": '<i class="fas fa-utensils"></i>'
};

function strToIcon(str) {
    str = str.trim();
    if (str in icons) {
        return icons[str];
    } else {
        return `<i class="fas fa-${str}"></i>`;
    }
}

function getIcons(item) {
    if ("icon" in item && item["icon"]) {
        return `<span class="icons">${item["icon"].split(",").map(strToIcon).join(" ")}</span>`;
    } else {
        return "";
    }
}

function descriptionToStr(description) {
    let lines = description.split("\n");
    if (lines.length == 1) {
        return description;
    } else {
        return lines.map(line => `<p>${line}</p>`).join("");
    }
}

function renderSchedule() {
    let scheduleSection = document.getElementsByClassName("info__section-content--schedule")[0];
    for (let date of schedule.dates) {
        let dateSubsection = document.createElement("div");
        dateSubsection.classList.add("info__subsection");
        dateSubsection.appendChild(htmlToElement(
            subsectionTitle({"text": date.text, "major": false})));
        for (let ev of date.events) {
            dateSubsection.appendChild(htmlToElement(eventTemplate(ev)));
        }
        scheduleSection.appendChild(dateSubsection);
    }
}

function renderQa() {
    let qaSection = document.getElementsByClassName("info__section-content--qa")[0];
    for (let qa of sectionContent["q&a"]) {
        if (!qa["ready"].startsWith("Ready")) {
            continue;
        }
        qa = {
            label: "Q",
            icon: null,
            link: null,
            title: qa["question"],
            details: [{text: descriptionToStr(qa["answer"])}],
        };
        qaSection.appendChild(htmlToElement(itemTemplate(_.defaults(qa))));
    }
}

// TODO: factor out commonality with 'renderLocal' (although differs because uses subsections)
function renderExtensibleSection(name) {
    let section = document.getElementsByClassName(`info__section-content--${name}`)[0];
    let breakThreshold = 6;
    let breakPoint = 4;
    let mainElements = [];
    let extensionElements = [];

    for (let item of sectionContent[name]) {
        if (!item["ready"].startsWith("Ready")) {
            continue;
        }
        item = {
            label: null,
            icon: null,
            title: item["name"] + getIcons(item),
            link: item["website"]
                ? {"url": item["website"], "text": "Website"}
                : null,
            details: [{text: descriptionToStr(item["description"])}],
        };
        let el = htmlToElement(itemTemplate(item));
        if (mainElements.length >= breakPoint
            && sectionContent[name].length >= breakThreshold) {
            extensionElements.push(el);
        } else {
            mainElements.push(el);
        }
    }
    for (let el of mainElements) {
        section.appendChild(el);
    }
    if (extensionElements.length) {
        let extension = document.createElement("div");
        extension.classList.add("info__section-extension");
        for (let el of extensionElements) {
            extension.appendChild(el);
        }

        let moreLink = document.createElement("a");
        moreLink.innerHTML = '<i class="fas fa-chevron-down"></i> More';
        moreLink.classList.add("info__section-more-link");
        moreLink.addEventListener("click", function () {
            extension.classList.add("info__section-extension--extended");
            moreLink.classList.add("info__section-more-link--hidden");
        });

        section.appendChild(moreLink);
        section.appendChild(extension);
    }
}

function renderLocalArea() {
    let localSection = document.getElementsByClassName("info__section-content--local")[0];
    let subsectionKeyToTitle = {
        "sightseeing": "Sightseeing",
        "pubs": "Pubs & Eateries",
        "walks": "Country Walks",
    };

    let breakThreshold = 6;
    let breakPoint = 4;
    for (let subsectionKey of Object.keys(subsectionKeyToTitle)) {
        let localSubsection = document.createElement("div");
        localSubsection.classList.add("info__subsection");
        localSubsection.appendChild(htmlToElement(subsectionTitle(
            {"text": subsectionKeyToTitle[subsectionKey], "major": true})));
        let mainElements = [];
        let extensionElements = [];

        for (let attraction of sectionContent[subsectionKey]) {
            if (!attraction["ready"].startsWith("Ready")) {
                continue;
            }
            attraction = {
                label: null,
                icon: null,
                title: attraction["name"] + getIcons(attraction),
                link: attraction["website"]
                    ? {"url": attraction["website"], "text": "Website"}
                    : null,
                details: [{text: descriptionToStr(attraction["description"])}],
            };
            let el = htmlToElement(itemTemplate(attraction));
            if (mainElements.length >= breakPoint
                && sectionContent[subsectionKey].length >= breakThreshold) {
                extensionElements.push(el);
            } else {
                mainElements.push(el);
            }
        }
        for (let el of mainElements) {
            localSubsection.appendChild(el);
        }
        localSection.appendChild(localSubsection);
        if (extensionElements.length) {
            let extension = document.createElement("div");
            extension.classList.add("info__subsection-extension");
            for (let el of extensionElements) {
                extension.appendChild(el);
            }

            let moreLink = document.createElement("a");
            moreLink.innerHTML = '<i class="fas fa-chevron-down"></i> More';
            moreLink.classList.add("info__subsection-more-link");
            moreLink.addEventListener("click", function () {
                extension.classList.add("info__subsection-extension--extended");
                moreLink.classList.add("info__subsection-more-link--hidden");
            });

            localSubsection.appendChild(moreLink);
            localSubsection.appendChild(extension);
        }
    }
}

const EMAIL_REGEX =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function getFormData(form) {
    return Object.fromEntries(new FormData(form));
}

function hideRsvpErrors() {
    for (let el of document.getElementsByClassName("info__rsvp-form-error")) {
        el.classList.add("info__rsvp-form-error--hidden");
    }
}

let validations = [
    (formData) => {
        let errors = [];
        if (!formData.firstName) {
            errors.push("first-missing");
        } else if (formData.firstName.length < 2) {
            errors.push("first-short");
        }
        if (!formData.lastName) {
            errors.push("last-missing");
        } else if (formData.lastName.length < 2) {
            errors.push("last-short");
        }

        return errors;
    },
    (formData) => {
        let errors = [];
        if (!formData.phone) {
            errors.push("phone-missing");
        } else if (formData.phone.length < 7) {
            errors.push("phone-invalid");
        } else if (formData.phone.replace(/[\s\d\(\)\+\-]/gi, "").length > 0) {
            errors.push("phone-invalid");
        }
        if (!formData.email) {
            errors.push("email-missing");
        } else if (!EMAIL_REGEX.test(formData.email)) {
            errors.push("email-invalid");
        }

        return errors;
    },
    (formData) => {
        let errors = [];
        if (!formData.events.length) {
            errors.push("events-empty");
        }
        if (formData.events.includes("none") && formData.events.length > 1) {
            errors.push("events-invalid");
        }

        return errors;
    },
    (formData) => {
        let errors = [];
        if (formData.events.includes("breakfast")) {
            if (formData.meal == "unselected") {
                errors.push(`meal-unselected`);
            }
        } else if (formData.meal != "unselected") {
            errors.push(`meal-selected`);
        }
        return errors;
    },
    (formData) => {
        let errors = [];
        let guestIds = Object.keys(formData.otherGuests);
        if (guestIds.length > 10) {
            errors.push("guests-numerous");
        }
        for (let id of guestIds) {
            let guest = formData.otherGuests[id];
            if (!guest.name) {
                errors.push(`guest-${id}-name-missing`);
            } else if (guest.name.length < 2) {
                errors.push(`guest-${id}-name-short`);
            }
            if (formData.events.includes("breakfast")) {
                if (guest.meal == "unselected") {
                    errors.push(`guest-${id}-meal-unselected`);
                }
            } else if (guest.meal != "unselected") {
                errors.push(`guest-${id}-meal-selected`);
            }
        }
        return errors;
    },
    (formData) => {
        let errors = [];
        if (formData.churchCoach) {
            if (!formData.events.includes("ceremony") || !formData.events.includes("breakfast")) {
                errors.push(`church-coach-selected`);
            }
        }
        if (formData.venueCoach) {
            if (!formData.events.includes("celebration")) {
                errors.push(`venue-coach-selected`);
            }
        }
        return errors;
    }
];

function standardiseDetails(formData) {
    let res = {};
    res.firstName = formData.firstName.trim();
    res.lastName = formData.lastName.trim();
    res.phone = formData.phone.trim();
    res.email = formData.email.trim();
    res.events = [];
    if (formData.ceremony == "on") {
        res.events.push("ceremony");
    }
    if (formData.breakfast == "on") {
        res.events.push("breakfast");
    }
    if (formData.celebration == "on") {
        res.events.push("celebration");
    }
    if (formData.none == "on") {
        res.events.push("none");
    }
    res.meal = formData["meal-primary"];
    let numberRegex = /[0-9]/;
    let namePrefix = "guest-name-";
    let mealPrefix = "meal-";
    let guests = {};
    for (let key of Object.keys(formData).filter(numberRegex.test.bind(numberRegex))) {
        let id = null;
        let guestKey = null;
        if (key.startsWith(namePrefix)) {
            id = key.substring(namePrefix.length);
            guestKey = "name";
        } else if (key.startsWith(mealPrefix)) {
            id = key.substring(mealPrefix.length);
            guestKey = "meal";
        }
        guests[id] = guests[id] || {};
        guests[id][guestKey] = formData[key].trim();
    }
    res.otherGuests = guests;
    res.dietaryReqs = formData.dietaryReqs.trim();
    res.churchCoach = formData.churchCoach == "on";
    res.venueCoach = formData.venueCoach == "on";
    res.comments = formData.comments.trim();
    res["form-name"] = formData["form-name"];
    return res;
}

function renderFormSubmit(coming) {
    let rsvpSection = document.getElementsByClassName("info__section-content--rsvp")[0];
    let rsvpSubmitted = htmlToElement(rsvpSubmittedTemplate({coming}));
    rsvpSection.appendChild(rsvpSubmitted);
    document.getElementsByClassName("info_rsvp-form-restart")[0].addEventListener("click", () => {
        rsvpSubmitted.remove();
        renderRsvpCode();
    });
}

function submitFormData(formData) {
    let i = 1;
    for (let id in formData.otherGuests) {
        formData[`otherGuest${i}Name`] = formData.otherGuests[id].name;
        formData[`otherGuest${i}Meal`] = formData.otherGuests[id].meal;
        i++;
    }
    delete formData.otherGuests;
    formData.events = formData.events.join(", ");
    return fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
    });
}

function addGuest(detailsForm, guestId) {
    let guests = detailsForm.getElementsByClassName("info__rsvp-form-guests")[0];
    let guest = htmlToElement(guestTemplate({
        guest: guestId, mealSelectTemplate : mealSelectTemplate}));
    guests.appendChild(guest);
    guest.getElementsByClassName("info_rsvp-form-guest-button--remove")[0].addEventListener("click", _.bind(guest.remove, guest));
}

function renderBreakfastRsvp(modifier) {
    let rsvpSection = document.getElementsByClassName("info__section-content--rsvp")[0];
    let rsvpDetails = htmlToElement(rsvpDetailsTemplate({
        mealSelectTemplate: mealSelectTemplate }));
    rsvpSection.appendChild(rsvpDetails);
    let detailsForm = rsvpDetails.getElementsByClassName("info__rsvp-form--details")[0];
    if (modifier) {
        detailsForm.classList.add(`info__rsvp-form--${modifier}`);
    }
    let submitting = false;
    let guestId = 0;
    detailsForm.getElementsByClassName("info_rsvp-form-guest-button--add")[0].addEventListener("click", () => { addGuest(detailsForm, guestId++); });
    detailsForm.addEventListener("submit", (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }
        submitting = true;
        console.log(getFormData(detailsForm));
        let formData = standardiseDetails(getFormData(detailsForm));
        hideRsvpErrors();
        let errors = [];
        for (let val of validations) {
            errors = errors.concat(val(formData));
        }
        for (let err of errors) {
            document.getElementsByClassName(`info__rsvp-form-error--${err}`)[0].classList.remove("info__rsvp-form-error--hidden");
        }
        if (errors.length) {
            document.getElementsByClassName(`info__rsvp-form-error--validation`)[0].classList.remove("info__rsvp-form-error--hidden");
            submitting = false;
        } else {
            let overlay = document.getElementsByClassName("info__rsvp-overlay")[0];
            overlay.classList.remove("info__rsvp-overlay--hidden");
            submitFormData(formData)
                .then(() => {
                    rsvpDetails.remove();
                    renderFormSubmit(!formData.events.includes("none"));
                })
                .catch(() => {
                    document.getElementsByClassName("info__rsvp-form-error--network")[0].classList.remove("info__rsvp-form-error--hidden");
                })
                .finally(() => {
                    overlay.classList.add("info__rsvp-overlay--hidden");
                    submitting = false;
                });
        }
    });
}

function renderEveningRsvp() {
    renderBreakfastRsvp("evening");
}

function renderCompleteBreakfastRsvp() {
    renderBreakfastRsvp("complete-breakfast");
}

function renderRsvpCode() {
    let rsvpSection = document.getElementsByClassName("info__section-content--rsvp")[0];
    let rsvpCode = htmlToElement(rsvpCodeTemplate());
    let codeToCallback = {"LATEMEAL": renderBreakfastRsvp, "JJWED2021": renderCompleteBreakfastRsvp, "JJWEDDINGAUG": renderEveningRsvp};
    rsvpSection.appendChild(rsvpCode);
    let codeForm = rsvpCode.getElementsByClassName("info__rsvp-form--code")[0];
    codeForm.addEventListener("submit", (e) => {
        e.preventDefault();
        let formData = getFormData(codeForm);
        hideRsvpErrors();
        let code = formData.code.trim().toUpperCase().replace(/\.$/, "");
        if (!code) {
            document.getElementsByClassName("info__rsvp-form-error--empty")[0].classList.remove("info__rsvp-form-error--hidden");
        } else if (!(code in codeToCallback)) {
            document.getElementsByClassName("info__rsvp-form-error--invalid")[0].classList.remove("info__rsvp-form-error--hidden");
        } else {
            rsvpCode.remove();
            codeToCallback[code]();
        }
        return false;
    }, false);
}

export function renderContent() {
    renderQa();
}
