import _ from "lodash"

export let itemTemplate = _.template(`<div class="info__subsection-card info__subsection-card--narrow <%= (!label && !icon) ? 'info__subsection-card--simple' : ''%>">
    <div class="info__subsection-title-bar">
        <div class="info__item-label <%= icon ? 'info__item-label--icon info__item-label--' + icon : '' %>"><%= label %></div>
        <div class="info__item-title"><%= title %></div>
    </div>
    <div class="info__subsection-details-wrapper">
        <div class="info__subsection-details-stub"></div>
        <div class="info__subsection-details">
            <% if (link) { %><a href="<%= link.url %>" class="info__link"><%= link.text %></a><% } %>
            <% for (let detail of details) { %>
            <div class="info__detail info__detail--info"><%= detail.text %></div>
            <% } %>
        </div>
    </div>
</div>`);


export let subsectionTitle = _.template(`<div class="info__subsection-title <%= major ? 'info__subsection-title--major' : '' %>">
    <span class="info__subsection-title-label"><%= text %></span>
</div>`);
