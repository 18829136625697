import { updateCountdown } from "./countdown";
import { updateImg } from "./cycle-images";
import { renderContent } from "./render-content";
import { handleScroll } from "./up-button";

setInterval(updateCountdown, 10);
setInterval(updateImg, 30000);
renderContent();
window.addEventListener("scroll", handleScroll);

setTimeout(() => {
    var top = document.getElementById(window.location.hash).offsetTop;
    window.scrollTo(0, top);
}, 100);
